/* Generated by Glyphter (http://www.glyphter.com) on  Fri Feb 03 2023*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-afootball:before{content:'\0041';}
.icon-baseball:before{content:'\0042';}
.icon-basketball:before{content:'\0043';}
.icon-bhistory:before{content:'\0044';}
.icon-bonus:before{content:'\0045';}
.icon-ca:before{content:'\0046';}
.icon-calender:before{content:'\0047';}
.icon-casino:before{content:'\0048';}
.icon-clock:before{content:'\0049';}
.icon-cricket:before{content:'\004a';}
.icon-cross:before{content:'\004b';}
.icon-cycling:before{content:'\004c';}
.icon-darts:before{content:'\004d';}
.icon-deposit:before{content:'\004e';}
.icon-details:before{content:'\004f';}
.icon-event:before{content:'\0050';}
.icon-favorites:before{content:'\0051';}
.icon-football:before{content:'\0052';}
.icon-game:before{content:'\0053';}
.icon-gift:before{content:'\0054';}
.icon-golf:before{content:'\0055';}
.icon-handball:before{content:'\0056';}
.icon-history:before{content:'\0057';}
.icon-home:before{content:'\0058';}
.icon-horse:before{content:'\0059';}
.icon-icehockey:before{content:'\005a';}
.icon-jackpot:before{content:'\0061';}
.icon-jersy:before{content:'\0062';}
.icon-linelock:before{content:'\0063';}
.icon-live:before{content:'\0064';}
.icon-livetwo:before{content:'\0065';}
.icon-lock:before{content:'\0066';}
.icon-logout:before{content:'\0067';}
.icon-message:before{content:'\0068';}
.icon-new:before{content:'\0069';}
.icon-notifivation:before{content:'\006a';}
.icon-pcontact:before{content:'\006b';}
.icon-pmart:before{content:'\006c';}
.icon-popular:before{content:'\006d';}
.icon-promos:before{content:'\006e';}
.icon-robot:before{content:'\006f';}
.icon-search:before{content:'\0070';}
.icon-setting:before{content:'\0071';}
.icon-slots:before{content:'\0072';}
.icon-star:before{content:'\0073';}
.icon-tablemart:before{content:'\0074';}
.icon-tennis:before{content:'\0075';}
.icon-top:before{content:'\0076';}
.icon-trush:before{content:'\0077';}
.icon-ttennis:before{content:'\0078';}
.icon-tvirtual:before{content:'\0079';}
.icon-twer:before{content:'\007a';}
.icon-user:before{content:'\0030';}
.icon-v:before{content:'\0031';}
.icon-vamerican:before{content:'\0032';}
.icon-vbasketball:before{content:'\0033';}
.icon-verify:before{content:'\0034';}
.icon-vfootball:before{content:'\0035';}
.icon-video:before{content:'\0036';}
.icon-volly:before{content:'\0037';}
.icon-withdraw:before{content:'\0038';}
.icon-poker:before{content: '\f155';}