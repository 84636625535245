/* Generated by Glyphter (http://www.glyphter.com) on  Fri Mar 08 2024*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-poker:before{content:'\0041';}
.icon-crash:before{content:'\0042';}
.icon-video-poker:before{content:'\0043';}
.icon-bingo:before{content:'\0044';}
.icon-scratch-card:before{content:'\0045';}
.icon-virtual-games:before{content:'\0046';}
.icon-video-slots:before{content:'\0047';}
.icon-all-games:before{content:'\0048';}
.icon-table-game:before{content:'\0049';}